import jquery from "jquery";

export default (window.$ = window.jQuery = jquery);

export function isTextUrl(data) {
    try {
        const url = new URL(data);
        return true;
    } catch (e) {}
    return false;
}