import "./lib/jquery-lib";

import main_html from "bundle-text:./layouts/main.html";
import header_html from "bundle-text:./layouts/doc_head.html";

import terms_html from "bundle-text:./layouts/terms.html";
import privacy_html from "bundle-text:./layouts/privacy.html";
import withdraw_html from "bundle-text:./layouts/withdraw.html";
import refund_html from "bundle-text:./layouts/refund.html";

import {isDeviceAndroid} from "./lib/device_checker";

const pageList = ["terms", "privacy", "withdraw", "refund"];

class Router {
    constructor() {
        this.body = $("body");
        const page = window.location.pathname.replace("/", "");
        if (page === "download") {
            this.onDownloadApp();
        } else {
            if (pageList.some((item) => item === page)) {
                this.onDocumentPage(page);
            } else {
                this.onMainPage();
            }
        }

    }

    onMainPage = () => this.body.append(main_html);

    onDocumentPage(page) {
        const documentElement = $('<div class="documents"></div>');
        documentElement.append(header_html);
        switch (page) {
            case "terms":
                documentElement.append(terms_html);
                break;
            case "privacy":
                documentElement.append(privacy_html);
                break;
            case "withdraw":
                documentElement.append(withdraw_html);
                break;
            case "refund":
                documentElement.append(refund_html);
                break;
        }
        this.body.append(documentElement);
    }

    onDownloadApp() {
        if(isDeviceAndroid()) {
            location.href='https://play.google.com/store/apps/details?id=com.joyend.freygo';
        } else {
            location.reload();
        }
    }
}

window.onload = () => new Router();